<template>
  <div class="streamCalc-slide">
    <!-- <backToHomeButton :title="backToHomeButtonText" /> -->
    <button class="close main-close-btn btn btn-link btn-sm btn-new" @click="$router.push({ name: 'Start' })">
      <img src="@/assets/images/newLogo.svg" alt=""><span v-text="backToHomeButtonText"></span>
    </button>
    <div class="app-inner" v-if="dataLoaded">
      <div class="slides-wrapper slides-wrapper-intro14 clip">
        <div class="a-slide active">
          <slide-headers
            class="slide-headers-top"
            :tariffs="tariffs"
            :state="slideState"
            :sportOffers="sportOffers"
            :tooltip="tooltip"
            :tipp="generalTippCombo"
            @handleTooltip="handleTooltip"
            @handleTariff="handleTariff"
            @handleTvAction="handleTvAction"
            @handleSportOffers="handleSportOffers"
            @reset="handleReset">
          </slide-headers>
          <slide-packages
            v-if="!sportOffers && packages.length"
            :packages="packages"
            :state="slideState"
            :baseUrl="baseUrl"
            :tooltip="tooltip"
            :triggerChange="triggerPackagesChange"
            @handleTooltip="handleTooltip"
            @handlePackages="handlePackages">
          </slide-packages>
          <slide-selection-details
            v-if="slideState && slideState.tariff && !sportOffers"
            :baseUrl="baseUrl"
            :state="slideState"
            :packages="packages"
            :details="details"
          ></slide-selection-details>
          <slide-sport-offer
            v-if="sportOffers"
            :baseUrl="baseUrl"
            :reset="sportReset"
            :packages="sportPackages"
            :sports="sports"
            :state="slideState"
            :tooltip="tooltip"
            :sportTypes="sportTypes"
            @handleTooltip="handleTooltip"
            @handlePackages="handlePackages"></slide-sport-offer>
            <slide-headers
            class="slide-headers-bottom"
            :tariffs="tariffs"
            :state="slideState"
            :sportOffers="sportOffers"
            :tooltip="tooltip"
            :tipp="generalTippCombo"
            @handleTooltip="handleTooltip"
            @handleTariff="handleTariff"
            @handleTvAction="handleTvAction"
            @handleSportOffers="handleSportOffers"
            @reset="handleReset">
          </slide-headers>
        </div>
      </div>
    </div>
    <infoWrapper
      v-if="showInfoBox"
      :isOpened="panels.infoWrapper"
      v-on:togglePanel="togglePanel"
      :tabs="infoBoxData"></infoWrapper>
    <outerLinkWrapper v-if="staticAssets && !!staticAssets.external_button" :baseUrl="baseUrl" :data="staticAssets"></outerLinkWrapper>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapState } from 'vuex'
import dynamicModule from '@/store/utils/dynamicModule'
import slidesStore from '@/store/modules/slides'
import SlideHeaders from '@/components/intro14-streaming-calculator/slide-headers.vue'
import SlidePackages from '@/components/intro14-streaming-calculator/slide-packages.vue'
import SlideSelectionDetails from '@/components/intro14-streaming-calculator/slide-selection-details.vue'
import SlideSportOffer from '@/components/intro14-streaming-calculator/slide-sport-offer.vue'
import infoWrapper from '@/components/infoWrapper'
import outerLinkWrapper from '@/components/outerLinkWrapper'
// import backToHomeButton from '@/components/backToHomeButton'


export default {
  mixins: [
    dynamicModule('slides', slidesStore)
  ],
  data () {
    return {
      currentSlide: 1,
      animate: true,
      showLoader: false,
      backToHomeButtonText: 'Streaming-Rechner',
      current: null,
      showIntro: false,
      showHome: true,
      isAnimating: false,
      dataLoaded: true,
      imageCache: [],
      panels: {
        'sideMenu': false,
        'infoWrapper': false,
        'bottomVideos': false,
        'videoWrapper': false
      },
      slideVideo: false,
      showInfoBox: false,
      infoBoxData: null,
      sportOffers: false,
      sportReset: false,
      tariffs: [],
      packages: [],
      sports: [],
      sportTypes: [],
      details: null,
      slideState: {
        tv_action: null,
        tariff: null,
        details: null,
        childsTotal: 0,
        selectedPackages: [],
        tariffActivated: false,
        package: null,
        addons: [],
        packageSuboption: []
      },
      triggerPackagesChange: true,
      tooltip: null,
      staticAssets: null,
      generalTippCombo: null
    }
  },
  computed: {
    ...mapState('slides', ['baseUrl', 'intro', 'introInfoPage', 'slides']),
    sportPackages () {
      return this.packages.filter(item => {
        return item.has_sports
      })
    }
  },
  components: {
    SlideHeaders,
    SlidePackages,
    SlideSelectionDetails,
    SlideSportOffer,
    infoWrapper,
    outerLinkWrapper
  },
  methods: {
    ...mapActions('slides', ['searchSlides', 'clearSlide', 'addLoading']),
    async handleTariff(item, bool = false) {
      bool && await this.handleReset(null, true)
      this.slideState.tariff = item
      bool && await this.checkTariffSuboptions()
      // this.$nextTick(() => {
      // })
    },
    handleTvAction (data) {
      this.slideState.tv_action = data
    },
    findSubPackagePrice (itemPackage) {
      // debugger
      // let price = 0
      let otherSubpackage = this.slideState.packageSuboption.find(item => item.id !== itemPackage.id && item.parent_id === itemPackage.parent_id)
      // if (otherSubpackage) price = otherSubpackage.price
      let packageCombo = itemPackage.combinations.find(comb => 
        (comb.tariff === this.slideState.tariff?.id && 
        ((otherSubpackage && comb.packages.includes(otherSubpackage.id)) || !otherSubpackage) )
      )
      
      return packageCombo
    },
    setSubPackage(item, ignore = true, packagePrice = null) {
      this.slideState.selectedPackages.indexOf(item.parent_id) === -1 && this.slideState.selectedPackages.push(item.parent_id)
      let activeSubIndex = this.packages.findIndex(pck => pck.parent_id === item.parent_id && pck.active)
      if(activeSubIndex !== -1 ) this.packages[activeSubIndex].active = false
      item.active = true
      if (!ignore) {
        let packageExists = this.slideState?.packageSuboption?.findIndex(pck => pck.parent_id === item.parent_id)
        if (packageExists !== -1) this.slideState.packageSuboption.splice(packageExists, 1) 
        this.$nextTick(() => {
          const mainComboPck = packagePrice !== null ? packagePrice : this.findSubPackagePrice(item)
          if (mainComboPck?.tipp) this.generalTippCombo = mainComboPck.tipp
          this.slideState.packageSuboption.push({
            id: item.id,
            parent_id: item.parent_id,
            price: mainComboPck.price,
            name: item.name,
            market_price: mainComboPck.market_price,
            addon: item.addon,
            tariffs: item.tariffs
          })
        })
      }
    },
    async handlePackages (item) {
      // let _this = this
      // First check if package has parent and is sub option??
      if (item.parent_id && item.is_sub_option) {
        this.triggerPackagesChange = false
        this.generalTippCombo = null
        if (!this.slideState?.tariff?.id || (!item.tariffs?.includes(this.slideState.tariff?.id) && !item.addon?.includes(this.slideState.tariff?.id)) && !this.slideState?.packageSuboption?.length) {
          // if (item.is_default_sub_option && !this.slideState?.packageSuboption?.length) {
          //   this.resetSubOptions()
          //   // let parentItm = this.packages.find(pckg => pckg.id === item.parent_id)
          //   this.tariffExists(item, item.tariffs?.length ? 'tariffs' : 'addon')
          // } else {
          //   this.resetSubOptions()
          //   if (item.is_unique && !this.slideState?.packageSuboption?.length) {
          //     this.tariffExists(item, 'addon')
          //   } else this.tariffExists(item, item.tariffs?.length ? 'tariffs' : 'addon')
          // }
          let comboI = item.combinations.find(combo => combo.default_suboption)
          if (comboI) {
            this.handleTariff(this.tariffs.find(t => t.id === comboI.tariff))
            this.generalTippCombo = comboI?.tipp
          } else this.tariffExists(item, item.tariffs?.length ? 'tariffs' : 'addon')

          this.setSubPackage(item, false)
        } else if (item.combinations?.length && this.slideState.packageSuboption?.length) {
          // debugger
          const validCombo = await this.validateCombinations(item)
          if (validCombo?.r === 'resetWithTariff') {
            // this.triggerPackagesChange = false
            let realPack = null
            for (const pck in this.packages) {
              if (this.packages[pck].parent_id == validCombo.sub.parent_id) this.packages[pck].active = null
              if (this.packages[pck].id === validCombo.sub.id) {
                realPack = this.packages[pck]
                this.packages[pck].active = true
              }
            }
            let packageExists = this.slideState?.packageSuboption?.findIndex(pck => pck.parent_id === realPack.parent_id)
            if (packageExists !== -1) this.slideState.packageSuboption.splice(packageExists, 1)
            
            let comboI = realPack.combinations.find(combo => combo.default_suboption)
            this.generalTippCombo = comboI?.tipp
            this.slideState.packageSuboption.push({
              id: realPack.id,
              parent_id: realPack.parent_id,
              // price: realPack.tariffs?.includes(this.slideState.tariff.id) ? realPack.price : realPack.unique_price,
              price: comboI.price,
              market_price: comboI.market_price,
              name: realPack.name,
              addon: realPack.addon,
              tariffs: realPack.tariffs
            })
            this.$nextTick(() => {
              this.triggerPackagesChange = true
            })
            return
          }
          !validCombo && this.resetSubOptions()
          if (validCombo && validCombo.tariff != this.slideState.tariff.id) {
            this.generalTippCombo = validCombo.tipp
            this.handleTariff(this.tariffs.find(tariff => tariff.id == validCombo.tariff))
            this.slideState.packageSuboption = []
            this.setSubPackage(this.packages.find(pckg => pckg.id == validCombo.packages[0]), false)
            this.setSubPackage(item, false)
          } else if (!validCombo && !item.tariffs?.includes(this.slideState.tariff.id) && !item.addon?.includes(this.slideState.tariff.id)) {
            this.generalTippCombo = item.combinations?.find(comb => comb.packages.includes(item.id))?.tipp
            this.tariffExists(item, item.is_unique ? 'addon' : (item.tariffs?.length ? 'tariffs' : 'addon'))
            this.setSubPackage(item, false)
          } else {
            if (!validCombo && item.is_unique) this.tariffExists(item, 'addon')
            this.setSubPackage(item, false)
          }
        } else {
          this.resetSubOptions()
          this.setSubPackage(item, false)
        }
        this.$nextTick(() => {
          this.triggerPackagesChange = true
        })
        return
      } else if (item.has_sub_options) {
        this.triggerPackagesChange = false
        if (!this.slideState?.packageSuboption?.length || this.slideState.packageSuboption.length == 2 || this.slideState?.packageSuboption[0]?.parent_id === item.id) {
          this.resetSubOptions()
          const defaultSub = this.packages.find(pckg => pckg.parent_id === item.id && pckg.is_default_sub_option)
          let comboI = defaultSub.combinations.find(combo => combo.default_suboption)
          if (comboI) {
            this.handleTariff(this.tariffs.find(t => t.id === comboI.tariff))
            this.generalTippCombo = comboI?.tipp
          } else this.tariffExists(defaultSub, defaultSub.tariffs?.length ? 'tariffs' : 'addon')
          this.setSubPackage(defaultSub, false)
        } else {
          let activeSuboption = this.packages.find(pckg => pckg.id === this.slideState.packageSuboption[0].id)
          let itemCombo = false
          for (let p = 0; p < this.packages.length; p++) {
            if (this.packages[p].is_sub_option) {
              itemCombo = this.packages[p].combinations?.find(comb => comb.packages?.includes(activeSuboption?.id))
              if (itemCombo) {
                itemCombo?.tariff != this.slideState?.tariff?.id && this.handleTariff(this.tariffs.find(tariff => itemCombo?.tariff == tariff.id))
                this.generalTippCombo = itemCombo?.tipp
                this.setSubPackage(activeSuboption, false)
                this.setSubPackage(this.packages[p], false)
                break
              }
            }
          }
        }
        this.$nextTick(() => {
          this.triggerPackagesChange = true
        })
        return
      }

      if (!item.is_sub_option) {
        const itemSelected = this.slideState?.selectedPackages?.indexOf(item.id)
        if (itemSelected !== -1) {
          this.slideState?.selectedPackages?.splice(itemSelected, 1)
          if (this.slideState?.package?.id === item.id) {
            this.handleChildPackages(item)
            this.slideState.package = null
          }
          const addonIndex = this.slideState.addons.indexOf(item.id)
          if (addonIndex !== -1) {
            if (item.parent_id && !item.is_sub_option) {
              this.slideState.childsTotal -= this.filterPrice(item)
            }
            this.slideState.addons.splice(addonIndex, 1)
          }
          return
        }
      }
      // first check is Tariff NULL: set active
      
      // case 1: package has Addons but not Tariffs
      // case 2: package has Tariffs but not Addons
      // case 3: package has both (Tariffs and Addons)
      !this.slideState?.tariff && this.tariffExists(item, 
        (!item.tariffs && item.addon)
        ? 'addon' 
        : (
            (!item.addon && item.tariffs)
              ? 'tariffs' 
              : (item.tariffs ? 'tariffs' : 'addon')
          )
      )

      // Check unique packages 
      if (item.is_unique && !item.tariffs?.includes(this.slideState?.tariff?.id)) {
        // check unique items
        if (this.slideState.selectedPackages.indexOf(item.id) > -1) this.slideState.selectedPackages.push(item.id)
        let uniquePackage = false
        for (let p = 0; p < this.packages.length; p++) {
          let pckItem = this.packages[p]
          if (pckItem.is_unique && pckItem.id !== item.id && pckItem.tariffs?.includes(this.slideState?.tariff?.id)) {
            pckItem.tariffs.forEach(itm => {
              if (item.tariffs.includes(itm)) {
                this.handleTariff(this.tariffs.find(trff => trff.id === itm))
                uniquePackage = true
              }
            })
          }
          if(uniquePackage) break
        }
        if (uniquePackage) return
      }

      !item.tariffs && item.addon && item.addon.length && this.handleItemAddon(item)
      if (item.tariffs?.length) {         
        if (item.addon?.includes(this.slideState.tariff.id)) {
          this.slideState.addons.push(item.id)
          this.slideState.selectedPackages.indexOf(item.id) === -1 && this.slideState.selectedPackages.push(item.id)
        } else if (!item.tariffs?.includes(this.slideState?.tariff?.id)) {
          this.slideState.selectedPackages = []
          this.tariffExists(item)
        } else if (this.slideState.selectedPackages.indexOf(item.id) === -1 && this.slideState?.package?.id !== item.parent_id) {
          this.slideState.selectedPackages.push(item.id)
        }
      }
      
    },
    async validateCombinations (item) {
      let validCombo = false
      let selfCombo = false
      let comboExist = false
      let resetWithTariff = false
      this.generalTippCombo = null
      const stateSub = this.slideState.packageSuboption.find(ps => ps.parent_id != item.parent_id) || this.slideState.packageSuboption[0]
      for (let i = 0; i < item.combinations.length; i++) {
        let itemCombination = item.combinations[i]
        if (itemCombination.packages?.includes(item.id) && itemCombination?.tariff == this.slideState.tariff.id) {
          this.generalTippCombo = itemCombination.tipp
          selfCombo = true
          comboExist = itemCombination
          break
        }
        if (itemCombination.packages?.includes(stateSub.id) && itemCombination?.tariff == this.slideState.tariff.id && !itemCombination.switch_tariff) {
          this.generalTippCombo = itemCombination.tipp
          validCombo = true
          comboExist = itemCombination
          break
        }
        if (itemCombination.packages?.includes(stateSub.id) && itemCombination.switch_tariff) {
          this.resetSubOptions(false)
          await this.handleTariff(this.tariffs.find(trff => trff.id === itemCombination.tariff), true)
          resetWithTariff = true
          break
        }
      }
      if (resetWithTariff) {
        return {r: 'resetWithTariff', sub: stateSub}
      }
      if (!validCombo && !selfCombo) {
        this.generalTippCombo = null
        comboExist = item.combinations.find(combinations => combinations.packages.includes(stateSub.id))
        this.generalTippCombo = comboExist?.tipp
      }
      return comboExist
    },
    tariffExists (item, type = 'tariffs') {
      let trffPrice = null
      let trff = null
      this.tariffs.forEach((tariff) => {
        if (item[type].includes(tariff.id)) {
          if (!trffPrice) {
            trffPrice = tariff.price
            trff = tariff
          }
          if (tariff.price < trffPrice) {
            trff = tariff
            trffPrice = tariff.price
          }
        }
      })
      this.handleTariff(trff)
      !item.is_sub_option && this.slideState.selectedPackages.indexOf(item.id) === -1 && setTimeout(() => {
        this.slideState.selectedPackages.push(item.id)
      }, 400)
    },
    handleItemAddon (item) {
      // if package Addons includes current Tariff
      if (item.addon.includes(this.slideState.tariff.id)) {
        // if current package is parent to any package
        if (item.is_parent && !item.has_sub_options) {
            // set active package
            this.slideState.package = item
            this.slideState.selectedPackages.indexOf(item.id) === -1 && this.slideState.selectedPackages.push(item.id)
            // remove child packages from selected packages and addons array this.slideState.selectedPackages && this.slideState.addons
            this.handleChildPackages(item)
        } else {
          if (this.slideState.selectedPackages.includes(item.parent_id) && !this.slideState.selectedPackages.includes(item.id)) {
            this.slideState.selectedPackages.push(item.id)
          }
          // if current package is not parent to any package, add to active Addons
          else {
            this.slideState.addons.push(item.id)
            this.slideState.selectedPackages.push(item.id)
            // check if item has parent
            if (item.parent_id && !item.is_sub_option) {
              this.slideState.childsTotal += this.filterPrice(item)
              this.checkParentPckgPrice(item.parent_id)
            }
          }
        }
      } else {
        this.tariffExists(item, 'addon')
      }
    },
    handleChildPackages (item) {
      let pckgIndex = null
      let addonIndex = null
      for (let i = 0; i < this.packages.length; i++) {
        if (this.packages[i].parent_id === item.id) {
          addonIndex = this.slideState.addons.indexOf(this.packages[i].id)
          pckgIndex = this.slideState.selectedPackages.indexOf(this.packages[i].id)
          pckgIndex !== -1 && this.$delete(this.slideState.selectedPackages, pckgIndex)
          addonIndex !== -1 && this.$delete(this.slideState.addons, addonIndex)
        }
      }
    },
    checkParentPckgPrice (parentId) {
      let parent = this.packages.find(item => item.id === parentId)
      if ((parent.price - parent.discount_24) <= this.slideState.childsTotal) {
        this.slideState.package = parent
        this.slideState.selectedPackages.indexOf(parent.id) === -1 && this.slideState.selectedPackages.push(parent.id)
        this.slideState.childsTotal = 0
        this.handleChildPackages(parent)
      }
    },
    handleSportOffers (val) {
      this.sportOffers = val
    },
    async handleReset (val = null, newFetch = false) {
      // this.sportOffers = false
      if (val === 's') {
        this.sportReset = true
        this.$nextTick(() => {
          this.sportReset = false
        })
      } else {
        this.sportReset = true
        this.resetState()
      }
      await this.loadSlide(!newFetch)
    },
    filterPrice (item, after = false) {
      return parseFloat(item.price) - parseFloat(after ? item.discount_after : item.discount_24)
    },
    handleTooltip (type, id) {
      this.tooltip = type ? type + '-' + id : type
      this.tooltip && document.querySelector('.' + this.tooltip)?.focus()
    },
    handleInfoBox (data) {
      this.infoBoxData = data?.content_elements || null
      this.showInfoBox = data?.content_elements?.length > 0
    },
    togglePanel (panelName, value) {
      for (let item in this.panels) {
        if (item === panelName) this.panels[panelName] = !value
        else this.panels[item] = false
      }
    },
    checkParentPackageSuboptions (parentId) {
      for (let i = 0; i < this.packages.length; i++) {
        let currentPackage = this.packages[i]
        if (currentPackage.id !== parentId && currentPackage.has_sub_options) {
          let subPackages = this.packages.filter(sub => sub.parent_id === currentPackage.id)
          for (let j = 0; j < subPackages.length; j++) {
            if (subPackages[j].tariffs?.includes(this.slideState.tariff?.id) || subPackages[j].addon?.includes(this.slideState.tariff?.id)) {
              this.setSubPackage(subPackages[j], false)
              break
            }
          }
        }
      }
    },
    checkActiveSuboptions (parentId) {
      let packageSub = this.slideState.packageSuboption.findIndex(sub => sub.parent_id !== parentId)
      if (packageSub !== -1) {
        let realPackageIndex = this.packages.findIndex(item => item.id === this.slideState.packageSuboption[packageSub].id)
        if ((this.slideState.packageSuboption[packageSub].tariffs?.includes(this.slideState.tariff?.id) || this.slideState.packageSuboption[packageSub].addon?.includes(this.slideState.tariff?.id))) {
          this.setSubPackage(this.packages[realPackageIndex], false)
        } else {
          this.slideState.packageSuboption.splice(packageSub, 1)
          let parentIndex = this.slideState.selectedPackages.indexOf(this.packages[realPackageIndex].parent_id)
          if (parentIndex !== -1) {
            this.slideState.selectedPackages.splice(parentIndex, 1)
          }
          // this.slideState.selectedPackages.splice(realPackageIndex, 1)
        }
      }
    },
    // async checkTariffSuboptions () {
    //   // this.slideState.packageSuboption = []
    //   let pck_ids = []
    //   await this.packages.forEach(item => {
    //     if (!pck_ids.includes(item.parent_id) 
    //       && item.is_sub_option 
    //       && item.price === 0 
    //       && item.tariffs?.includes(this.slideState.tariff?.id)) {
    //       pck_ids.push(item.parent_id)
    //       this.setSubPackage(item, false)
    //     }
    //   })
    // },
    async checkTariffSuboptions() {
      return new Promise(async (resolve, reject) => {
        try {
          let pck_ids = [];
          for (const item of this.packages) {
            if (
              item.is_sub_option &&
              item.price === 0 &&
              item.tariffs?.includes(this.slideState.tariff?.id) &&
              !pck_ids.includes(item.parent_id)
            ) {
              pck_ids.push(item.parent_id);
              this.setSubPackage(item, false);
            }
          }
          resolve();
        } catch (error) {
          reject(error);
        }
      })
    },
    resetSubOptions (setDef = true) {
      this.slideState.packageSuboption = []
      for (let i = 0; i < this.packages.length; i++) {
        if (this.packages[i].is_sub_option) {
          this.packages[i].active = setDef && this.packages[i].is_default_sub_option
          let parentIndex = this.slideState.selectedPackages.indexOf(this.packages[i].parent_id)
          if (parentIndex !== -1) {
            this.slideState.selectedPackages.splice(parentIndex, 1)
          }
        }
      }
    },
    async loadSlide (initSub = true) {
      await this.searchSlides(14).then(resp => {
        this.tariffs = resp.data.tariffs
        this.packages = resp.data.packages
        this.sports = resp.data.sports
        this.sportTypes = resp.data.sport_types
        this.details = resp.data.details
        this.staticAssets = resp.data?.static?.length > 0 ? resp.data.static[0] : null
        this.handleInfoBox(resp.data.info_page)
        this.resetSubOptions()
        // this.infoBoxData = resp.data.info_page || null
        // this.showInfoBox = this.infoBoxData
        setTimeout(() => {
          this.dataLoaded = true
        }, 500)
        if (this.$route?.params?.slug === 'sportangebot') {
          this.handleSportOffers(true)
        }
      })
    },
    resetState () {
      this.slideState = {
        tv_action: null,
        tariff: null,
        details: null,
        childsTotal: 0,
        selectedPackages: [],
        tariffActivated: false,
        package: null,
        addons: [],
        packageSuboption: []
      }
      this.generalTippCombo = null
      this.sportReset = false
    },
    subPackages (item) {
      return this.packages.filter(sub => sub.parent_id === item.id)
    }
  },
  mounted () {
    this.loadSlide()
  },
  created () {},
  beforeDestroy () {
    this.clearSlide()
  }
}
</script>
